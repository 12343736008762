<template>
  <div class="app-header-bottom">
    <div class="aht_nav">
      <div class="aht_nav_l">
        <router-link :to="{path:'/fhome'}"><img src="../icon/logo.png"></router-link>
      </div>
      <div v-if="$route.meta.authority=='1'" class="aht_nav_r">
        <el-input @keyup.enter.native="search" clearable @clear="search" placeholder="请输入内容" v-model="input3" class="input-with-select">
          <el-button @click="search" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-header-bottom",
  data() {
    return {
      input3:'',
    };
  },

  methods: {
    search(){

      this.$store.commit('updateSearchName',this.input3)
    }
  },
};
</script>

<style lang="less" scoped>
.app-header-bottom {
  width: 100%;
  height: 105px;
  background: white;
  .aht_nav{
    width: 1200px;
    margin: 0px auto;
    height: 105px;
    .aht_nav_l{
      float: left;
      margin-top: 38px;
      cursor: pointer;
    }
    .aht_nav_r{
      float: right;
      margin-top: 38px;
    }
  }
}

</style>


